import React, { useContext, useEffect, useRef, useState } from "react";
import { useDocument } from 'react-firebase-hooks/firestore';
import { compose, withProps } from "recompose";
import { HOME as ROUTE_DASHBOARD } from "../../utils/siteRoutes";
import SiteContext, { connectSiteContext } from "../../utils/siteContext";
import { withPageWrapper } from "../../components/shared/PageWrapper/PageWrapperComponent";
import { navigate } from 'gatsby';
import { auth, firestore, useFirestoreDoc } from "gatsby-theme-firebase";

import SDGModal from "../../components/shared/Modal/SDGModal/SDGModalComponent";

const fetchSDG = firebase =>
  () => firebase.firestore.collection('users').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid);

const createSDG = firebase =>
  () => firebase.firestore.collection('users').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid).update({ email: firebase.auth.currentUser.email, sdg: { value: 0, label: 'none' } });

const updateSDG = firebase => updatedSDG => {
  if(firebase.auth.currentUser)
    firebase.firestore.collection('users').doc(firebase.auth.currentUser.uid)
      .update(updatedSDG);
}

const mapContextStateToProps = ({ state: { firebase } }) => ({
  fetchAppFirestore: fetchSDG(firebase),
  createAppFirestore: createSDG(firebase),
  updateAppFirestore: updateSDG(firebase),
  submitAppFirestore: updateSDG(firebase)
});

const enhance = compose(
 connectSiteContext(mapContextStateToProps),
  withProps({
    isCurUser: true
  })
);

const SDGViewContainer = ({
  modal,
  closeTo,
  prevPath,
  fetchAppFirestore,
  createAppFirestore,
  updateAppFirestore,
  submitAppFirestore,
  someUser,
  history,
  prevLoc,
  isCurUser
}) => {

  const [ appState, updateAppState ] = useState("FETCHING");
  const [ localAppInfo, updateLocalAppInfo ] = useState({sdg: { value: 0, label: 'none' } });
  // const { dispatch } = useContext(SiteContext);
  const appRef = useRef();
  appRef.current = localAppInfo;

  let curUser = auth.currentUser;

  const [value, isLoading, error] = useFirestoreDoc(
    firestore.collection("users").doc(curUser && curUser.uid),
  );

  if (isLoading)
    return null;

  if (error)
    console.log(error);

  const updateAppInfo = newAppInfo => {
    const newLocalAppInfo = {
      ...localAppInfo,
      ...newAppInfo,
    };

    // updateLocalAppInfo(newLocalAppInfo);
//    if(!newLocalAppInfo.value) dispatch({ type: "UPDATE_DASHBOARD_TOAST", data: { toastName: "appModified" } });
  }

  const submitAppInfo = (value, label) => {
    if (value && label) {
      createAppFirestore();
      submitAppFirestore({ sdg: { value: value, label: label } });
    }
    updateAppState("SUBMITTING");
  //   dispatch({ type: "UPDATE_SDG", data: { sdg: { value: value, label: label } } });
      navigate(closeTo);
  }


  if(value) {
    if(value.exists && ((appState === "FETCHING") || (value.data().sdg && appState === "SUBMITTING"))) {
      updateAppState("FETCHED");
      // updateLocalAppInfo(value.data().sdg)
      // dispatch({ type: "UPDATE_SDG", data: { value: value.data().sdg.value, label: value.data().sdg.name } });
    } else if(!value.exists && (appState === "FETCHING")) {
      updateAppState("FETCHED");
    }
  }


  // save to firestore before component unmounts or page unloads
  /** useEffect(() => {
    window.addEventListener('beforeunload', () => updateAppFirestore(appRef.current));
    dispatch({ type: "UPDATE_TITLE", data: { title: "My Application" }});

    return () => {
      window.removeEventListener('beforeunload', () => updateAppFirestore(appRef.current))
      // updateAppFirestore(appRef.current);
    }
  }, []) */


  return (
      <SDGModal modal={modal} someUser={someUser} history={history} prevLoc={prevLoc} isCurUser={isCurUser} curUserName={curUser && curUser.name} updateAppInfo={updateAppInfo} submitAppInfo={submitAppInfo} appState={appState} curAppInfo={localAppInfo} />
  );
};

export default enhance(SDGViewContainer);
