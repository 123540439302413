/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { auth, useAuth } from "gatsby-theme-firebase";
import { Gallery } from "gatsby-theme-gallery";
import Button from "gatsby-theme-firebase/src/components/Button";
import LoginModal from "../components/LoginModal";
import { Code, CodeBlock, Header, A } from "../components/Styles";
import { Layout } from "../components";

import SDGViewContainer from '../sections/sdgs/SDGViewContainer';
import LoginSignupViewContainer from '../sections/login+signup/LoginSignupViewContainer'

const SDGs = () => {
  const [toggleLogin, setToggleLogin] = React.useState(false);
  const { isLoading, isLoggedIn, profile } = useAuth();

  if (isLoading) {
    return null;
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <Layout>
        <>
          {isLoggedIn ? (
            <div>
              <SDGViewContainer modal={modal} closeTo={closeTo}/>
            </div>
            ) : (
            <div>
              <LoginSignupViewContainer />
            </div>
          )}

          {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
        </>
        </Layout>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default SDGs;
